/* Navbar section */
  .navbar {
    position: fixed;
    top: 62px;
    width: 64px;
    height: 100vh;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease-in-out;
    z-index: 2;
  }

  .navbar.dark {
    background-color: rgb(52,53,65);
  }

  .navbar.open {
    width: 250px;
    transition: width 0.3s ease-in-out;
  }

  .open-navbar .page-container {
    transform: translateX(186px);
    width: calc(100% - 186px);
  }

  .close-navbar .page-container.open-navbar {
    transform: translateX(0px);
    width: calc(100%);
  }

  .navbar-content{
    margin-top: 10px;
  }

  .navbar-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    color: #000000;
    text-decoration: none;
    line-height: 1;
    flex-wrap: nowrap;
    font-size: 15px;
  }

  .navbar-link:after {
    content: "";
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    bottom: 0;
    border-radius: 8px;
    background-color: rgba(121, 118, 118, 0.2);
    pointer-events: none; /* make the pseudo-element ignore mouse events */
    opacity: 0; /* start with the pseudo-element hidden */
  }
  
  .navbar-link:not(.open):hover:after {
    opacity: 1;
  }
  
  .navbar-link.open {
    padding: 8px;
    border-radius: 8px;
  }
  
  .navbar-link:hover {
    color: #000000;
  }

  .navbar-link-button {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
    color: #000000;
    text-decoration: none;
    line-height: 1;
    flex-wrap: nowrap;
    font-size: 15px;

    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
    outline: none;
    width: 100%
  }

  .navbar-link-button:after {
    content: "";
    position: absolute;
    top: 0;
    left: 8px;
    right: 8px;
    bottom: 0;
    border-radius: 8px;
    background-color: rgba(121, 118, 118, 0.2);
    pointer-events: none; /* make the pseudo-element ignore mouse events */
    opacity: 0; /* start with the pseudo-element hidden */
  }
  
  .navbar-link-button:not(.open):hover:after {
    opacity: 1;
  }
  
  .navbar-link-button.open {
    padding: 8px;
    border-radius: 8px;
  }
  
  .navbar-link-button:hover {
    color: #000000;
  }

  .navbar-link-help {
    margin-top: auto; /* push the link to the bottom of the navbar */
  }

  .navbar-icon {
    margin-left: 12px;
    width: 20px;
    flex-shrink: 0;
    color: rgb(113, 130, 156);
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .navbar-icon.dark {
    color: rgba(197,197,210);
  }

  .navbar-text {
    display: none;
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .navbar.open .navbar-text {
    display: inline-block;
    max-width: 200px;  /* Adjust this value according to your needs */
    transition: max-width 0.3s ease-in-out;
  }  

  .navbar-text.dark {
    color: rgba(197,197,210);
  }

  .navbar-separator {
    height: 1px;
    border: none;
    margin: 12px;
    background-color: #ccc;
  }

  .navbar-link:active, .navbar-link:focus {
    outline: none;
  }

  .modal-title, .modal-text {
    text-align: center; /* Centering text */
  }
