/* CHART */
.chart-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    max-width: 900px;
    width: 70%;
    height: 60vh;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    flex: 2;
  }

  .chart-card.dark {
    color: rgba(197,197,210);
    background-color: rgb(52,53,65);
  }
  
  .card-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
    
  .chart-card-body {
    width: 100%;
    height: 90%;
  }

  /* DESCRIPTION */
  .description-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    margin-left: 20px;
    /* max-width: 450px; */
    max-height: 60vh;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .description-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description-card-body {
    max-height: 55vh;
    min-height: 30px; /* Adjust this to your preferred maximum height */
    overflow: auto; /* Enable scrolling */
    position: relative; /* Required for the pseudo-element to position correctly */
    margin-bottom: 5px;
  }
  
  /* Custom scrollbar styles (Webkit) */
  .description-card-body::-webkit-scrollbar {
    width: 5px;
  }
  
  .description-card-body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    border: 3px solid transparent;
  }
  
  .description-card-body::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  
  .generate-description-button {
    background-color: #4CAF50; 
    border: none; 
    color: white;
    padding: 2px 4px; 
    text-align: center; 
    text-decoration: none; 
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 12px; 
    transition-duration: 0.4s; 
    cursor: pointer; 
    border-radius: 20px;
    width: 180px; 
    height: 20px;
    bottom: 0;
  }
  
  .generate-description-button:hover {
    background-color: #3e8e41; 
  }
  
  .generate-description-button:active {
    background-color: #2c693d;
    transition-duration: 0.1s; 
  }

  .description-card.dark {
    color: rgba(197,197,210);
    background-color: rgb(52,53,65);
  }

  /* CONTAINER */
  .chart-description-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
  }
  
  @media (max-width: 1200px) {
    .chart-description-container {
      display: block;
    }
  
  .chart-card {
    width: auto;
    margin-right: 0px;
    flex: 1;
  }

  .description-card {
    margin-left: 0px;
    flex: 1;
    max-width: 900px;
  }

}
