form {
  background-color: #f7f7f8;
  padding: 2em;
  border-radius: .5em;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
}

input[type='text'],
input[type='password'] {
  padding: 0.5em;
  margin: 0.5em;
  border-radius: 0.3em;
  border: 1px solid transparent;
  box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.1);
  font-size: 1em;
  width: 250px;
}

input[type='text']::placeholder,
input[type='password']::placeholder {
  color: #aaa;
}

input[type='text']:focus::placeholder,
input[type='password']:focus::placeholder {
  color: transparent;
}

/* input[type='text']:hover,
input[type='password']:hover {
  border: 1px solid #4CAF50;
}

input[type='text']:focus,
input[type='password']:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: none; 
} */

.login-signup-button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 24px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px; /* Font size */
  margin: 4px 2px; /* Margin */
  transition-duration: 0.4s; /* Add transition on hover */
  cursor: pointer; /* Add cursor pointer */
  border-radius: 20px; /* Rounded edges */
  width: 250px; /* Width of button */
  height: 40px;
}

.login-signup-button:hover {
  background-color: #3e8e41; /* Darker green on hover */
}

.login-signup-button:active {
  background-color: #2c693d; /* Darker green when clicked */
  transition-duration: 0.1s; /* Shorten transition duration on click */
}

.email-message {
  /* font-style: italic; /* Set font style to italic */
  font-size: 12px; /* Set font size */
  margin-top: 5px; /* Add margin */
  text-align: center; /* Center text */
  color: #666; /* Set color to gray */
}

.error-message {
  /* font-style: italic; /* Set font style to italic */
  font-size: 14px; /* Set font size */
  margin-top: 5px; /* Add margin */
  text-align: center; /* Center text */
  color: #c71010; /* Set color to gray */
}