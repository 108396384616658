/* CHART */
.home-chart-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    max-height: 500px;
    /* max-width: 700px; */
    margin-bottom: 40px;
    height: 310px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .home-chart-card.dark {
    color: rgba(197,197,210);
    background-color: rgb(52,53,65);
  }
  
  .home-card-title {
    font-size: 16px;
    font-weight: bold;
  }
    
  .home-chart-card-body {
    width: 100%;
    height: 100%;
    max-height: 500px;
  }

  /* TABLE */
  .home-table-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .home-table-card.dark {
    color: rgba(197,197,210);
    background-color: rgb(52,53,65);
  }

  .home-table-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .home-table-card-body {
    max-height: 900px;
    min-height: 30px; /* Adjust this to your preferred maximum height */
    overflow: auto; /* Enable scrolling */
    position: relative; /* Required for the pseudo-element to position correctly */
    margin-bottom: 5px;
    width: 100%;
  }

  /* DESCRIPTION */
  .home-description-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    margin-right: 20px;
    width: 40%;
    /* min-width: 450px;
    max-width: 650px; */
    height: 700px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
  }

  .home-description-card-title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .home-description-card-body {
    max-height: 650px;
    min-height: 30px; /* Adjust this to your preferred maximum height */
    overflow: auto; /* Enable scrolling */
    position: relative; /* Required for the pseudo-element to position correctly */
    margin-bottom: 5px;
  }
  
  /* Custom scrollbar styles (Webkit) */
  .home-description-card-body::-webkit-scrollbar {
    width: 5px;
  }
  
  .home-description-card-body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
    border: 3px solid transparent;
  }
  
  .home-description-card-body::-webkit-scrollbar-thumb:hover {
    background: grey;
  }


  .home-description-card.dark {
    color: rgba(197,197,210);
    background-color: rgb(52,53,65);
  }

  /* CONTAINERS */
  .home-chart-description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .stacked-charts-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  @media (max-width: 1200px) {
    .home-chart-description-container {
      flex-direction: column;
      height: auto;
      width: 100%;
    }

    .stacked-charts-container {
      width: 100%;
    }
  
    .home-description-card, .home-chart-card, .home-table-card {
      width: auto;
      height: auto;
      margin-right: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
