table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border-bottom: 1px solid rgba(197,197,210, 0.2);
    padding: 15px;
    text-align: left;
  } 

  .total-row {
    border-top: 2px solid;
    border-bottom: 0px solid;
    font-weight: bold;
  }

  .index-col {
    font-weight: bold;
  }
  
  .header-roster {
    color: #81b1d2;
  }
  
  .header-waiver {
    color: #fa8174;
  }

  .total-roster {
    color: #81b1d2;
  }
  
  .total-waiver {
    color: #fa8174;
  }

