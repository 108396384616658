body {
  background-color: #f7f9fa;
  margin: 0;
  font-family: sSöhne,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,Helvetica Neue,Arial,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

.body.dark {
  background-color: rgba(68,70,84);
}

.login-page {
  background: radial-gradient(circle, #f5f5f5 0%, #e0e0e0 50%, #f5f5f5 75%);
}

.page-container {
  width: 100%;
  transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* transform: translateX(0); */
}


/* HOME PAGE CSS */
.home-takeaway-card-container{
  margin-left: 0px;
}


/* CHART PAGE CSS */
/* NAVIGATION LIST DOES NOT STAY PUT WHEN NAVBAR IS OPEN */
.page-container.open-navbar {
  transform: translateX(186px);
  width: calc(100% - 186px);
}

.page-body-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
  overflow: hidden;
}

.page-body-content{
  position: relative;
  /* display: flex; */
  align-items: flex-start;
  flex-direction: row; 
  justify-content: center;
  width: 85%;
  margin: 0 auto;
  left: 32px;
  overflow: hidden;
}

.home-page .page-body-content {
  width: 70%;
  margin: 0 auto;
}

.navigation-container {
  position: sticky;
  top: 0;
  width: 200px;
  margin-right: 20px;
}

.navigation-list {
  position: fixed;
  width: 200px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigation-list li {
  cursor: pointer;
  align-items: center;
  padding: 8px;
  color: #000000;
  text-decoration: none;
  line-height: 1;
  position: relative;
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
}

.navigation-list li.dark {
  color: rgba(197,197,210);
}

.navigation-list li:hover {
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(76, 175, 80, 0.2);
}

.navigation-list li.active-chart {
  color: #4CAF50;
  border-radius: 8px;
  opacity: 1;
  background-color: rgba(76, 175, 80, 0.2);
}

.card-container{
  margin-left: 220px;
}

@media (max-width: 1240px) {
  .navigation-container {
    display: none; /* Hide the navigation container */
  }

  .navigation-list {
    display: none; /* Hide the navigation list */
  }

  .card-container {
    margin-left: 0; /* Adjust the margin to remove the space */
  }

  .home-takeaway-card-container {
    margin-left: 0; /* Adjust the margin to remove the space */
  }

}

.rotate-icon {
  transition: transform 0.2s ease-in-out;
  margin-top: 4px;
  margin-left: 4px;
}

.rotate {
  transform: rotate(180deg);
}


/* Temporary - logo animation */
.Atom {
  width: 73px;
  height: 73px;
  position: relative;
  border-radius: 50%;
}

.Atom-nucleus,
.Atom-nucleus:before {
  position: absolute;
  background: linear-gradient(-180deg, #61DBFB 0%, #61DBFB 100%);
}

.Atom-nucleus {
  display: block;
  content: "";
  width: 25px;
  height: 25px;
  margin-left: -12.5px;
  margin-top: -12.5px;
  border-radius: 50%;
  z-index: 2;
}

.Atom-nucleus:before {
  display: block;
  content: "";
  width: 49px;
  height: 49px;
  margin-left: -24.5px;
  margin-top: -24.5px;
  border-radius: 50%;
  top: 12.5px;
  left: 12.5px;
  opacity: 0.1;
}

.Atom-orbit {
  position: absolute;
  width: 73px;
  height: 73px;
  border: solid 4px transparent;
  transform-style: preserve-3d;
}

.Atom-orbit--visible {
  border-radius: 50%;
  border-color: white;
}

.Atom-electron {
  transform-style: preserve-3d;
}
.Atom-electron:before {
  display: block;
  content: "";
  width: 24px;
  height: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  position: absolute;
  top: 36.5px;
  left: 36.5px;
}
.Atom-electron:after {
  display: block;
  content: "";
  width: 12px;
  height: 12px;
  margin-left: -6px;
  margin-top: -6px;
  border-radius: 50%;
  position: absolute;
  top: 36.5px;
  left: 36.5px;
  border-color: rgba(255, 255, 255, 0.4);
}

.Atom-orbit--top.Atom-orbit--visible, #inner-ellipse-top {
  transform: rotateZ(0deg) rotateY(70deg);
}

.Atom-orbit--top.Atom-orbit--foreground, #inner-ellipse-top {
  transform: translateZ(100px) rotateZ(0deg) rotateY(70deg);
}

@-webkit-keyframes rotate-top {
  0% {
    transform: rotate(0deg) translate(-36.5px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-36.5px) rotate(-360deg);
  }
}

@keyframes rotate-top {
  0% {
    transform: rotate(0deg) translate(-36.5px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-36.5px) rotate(-360deg);
  }
}
@-webkit-keyframes zind-left {
  0% {
    z-index: 1;
  }
  49% {
    z-index: 1;
  }
  50% {
    z-index: 3;
  }
  100% {
    z-index: 3;
  }
}
@keyframes zind-left {
  0% {
    z-index: 1;
  }
  49% {
    z-index: 1;
  }
  50% {
    z-index: 3;
  }
  100% {
    z-index: 3;
  }
}
@-webkit-keyframes zind-right {
  0% {
    z-index: 3;
  }
  49% {
    z-index: 3;
  }
  50% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}
@keyframes zind-right {
  0% {
    z-index: 3;
  }
  49% {
    z-index: 3;
  }
  50% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}
@-webkit-keyframes zind-top {
  0% {
    z-index: 1;
  }
  24% {
    z-index: 1;
  }
  25% {
    z-index: 3;
  }
  75% {
    z-index: 3;
  }
  76% {
    z-index: 1;
  }
}
@keyframes zind-top {
  0% {
    z-index: 1;
  }
  24% {
    z-index: 1;
  }
  25% {
    z-index: 3;
  }
  75% {
    z-index: 3;
  }
  76% {
    z-index: 1;
  }
}
.Atom-orbit--top {
  -webkit-animation: zind-top infinite linear;
          animation: zind-top infinite linear;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
}
.Atom-orbit--top .Atom-electron {
  -webkit-animation: rotate-top infinite linear;
          animation: rotate-top infinite linear;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  transform: rotate(0deg) translate(-36.5px) rotate(0deg);
}
.Atom-orbit--top .Atom-electron:before, .Atom-orbit--top .Atom-electron:after {
  transform: rotateY(-70deg) rotateZ(0deg);
}
.Atom-orbit--top .Atom-electron:before {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  opacity: 0.2;
}
.Atom-orbit--top .Atom-electron:after {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  box-shadow: 0px 0px 5px 0px #4CAF50;
}

.Atom-orbit--left.Atom-orbit--visible, #inner-ellipse-left {
  transform: rotateZ(-60deg) rotateY(70deg);
}

.Atom-orbit--left.Atom-orbit--foreground, #inner-ellipse-left {
  transform: translateZ(100px) rotateZ(-60deg) rotateY(70deg);
}

@-webkit-keyframes rotate-left {
  0% {
    transform: rotate(60deg) translate(-36.5px) rotate(-60deg);
  }
  100% {
    transform: rotate(420deg) translate(-36.5px) rotate(-420deg);
  }
}

@keyframes rotate-left {
  0% {
    transform: rotate(60deg) translate(-36.5px) rotate(-60deg);
  }
  100% {
    transform: rotate(420deg) translate(-36.5px) rotate(-420deg);
  }
}
@keyframes zind-left {
  0% {
    z-index: 1;
  }
  49% {
    z-index: 1;
  }
  50% {
    z-index: 3;
  }
  100% {
    z-index: 3;
  }
}
@keyframes zind-right {
  0% {
    z-index: 3;
  }
  49% {
    z-index: 3;
  }
  50% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}
@keyframes zind-top {
  0% {
    z-index: 1;
  }
  24% {
    z-index: 1;
  }
  25% {
    z-index: 3;
  }
  75% {
    z-index: 3;
  }
  76% {
    z-index: 1;
  }
}
.Atom-orbit--left {
  -webkit-animation: zind-left infinite linear;
          animation: zind-left infinite linear;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}
.Atom-orbit--left .Atom-electron {
  -webkit-animation: rotate-left infinite linear;
          animation: rotate-left infinite linear;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
  transform: rotate(60deg) translate(-36.5px) rotate(-60deg);
}
.Atom-orbit--left .Atom-electron:before, .Atom-orbit--left .Atom-electron:after {
  transform: rotateY(-70deg) rotateZ(60deg);
}
.Atom-orbit--left .Atom-electron:before {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  opacity: 0.2;
}
.Atom-orbit--left .Atom-electron:after {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  box-shadow: 0px 0px 5px 0px #4CAF50;
}

.Atom-orbit--right.Atom-orbit--visible, #inner-ellipse-right {
  transform: rotateZ(60deg) rotateY(70deg);
}

.Atom-orbit--right.Atom-orbit--foreground, #inner-ellipse-right {
  transform: translateZ(100px) rotateZ(60deg) rotateY(70deg);
}

@-webkit-keyframes rotate-right {
  0% {
    transform: rotate(120deg) translate(-36.5px) rotate(-120deg);
  }
  100% {
    transform: rotate(480deg) translate(-36.5px) rotate(-480deg);
  }
}

@keyframes rotate-right {
  0% {
    transform: rotate(120deg) translate(-36.5px) rotate(-120deg);
  }
  100% {
    transform: rotate(480deg) translate(-36.5px) rotate(-480deg);
  }
}
@keyframes zind-left {
  0% {
    z-index: 1;
  }
  49% {
    z-index: 1;
  }
  50% {
    z-index: 3;
  }
  100% {
    z-index: 3;
  }
}
@keyframes zind-right {
  0% {
    z-index: 3;
  }
  49% {
    z-index: 3;
  }
  50% {
    z-index: 1;
  }
  100% {
    z-index: 1;
  }
}
@keyframes zind-top {
  0% {
    z-index: 1;
  }
  24% {
    z-index: 1;
  }
  25% {
    z-index: 3;
  }
  75% {
    z-index: 3;
  }
  76% {
    z-index: 1;
  }
}
.Atom-orbit--right {
  -webkit-animation: zind-right infinite linear;
          animation: zind-right infinite linear;
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s;
}
.Atom-orbit--right .Atom-electron {
  -webkit-animation: rotate-right infinite linear;
          animation: rotate-right infinite linear;
  -webkit-animation-duration: 0.9s;
          animation-duration: 0.9s;
  transform: rotate(120deg) translate(-36.5px) rotate(-120deg);
}
.Atom-orbit--right .Atom-electron:before, .Atom-orbit--right .Atom-electron:after {
  transform: rotateY(-70deg) rotateZ(-60deg);
}
.Atom-orbit--right .Atom-electron:before {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  opacity: 0.2;
}
.Atom-orbit--right .Atom-electron:after {
  background: linear-gradient(-180deg, #4CAF50 0%, #4CAF50 100%);
  box-shadow: 0px 0px 5px 0px #4CAF50;
}

#react {
  width: 80px;
  height: 80px;
  position: relative;
}

#inner-ellipse-left {
  border: 5px solid #4CAF50;
  width: 73px;
  height: 73px;
  position: absolute;
  border-radius: 50%;
}

#inner-ellipse-right {
  border: 5px solid #4CAF50;
  width: 73px;
  height: 73px;
  position: absolute;
  border-radius: 50%;
}

#inner-ellipse-top {
  border: 5px solid #4CAF50;
  position: absolute;
  width: 73px;
  height: 73px;
  border-radius: 50%;
}

#dot {
  position: absolute;
  width: 20px;
  height: 10px;
  background-color: #4CAF50;
  border-radius: 50%;
  top: 45%;
  right: 35%;
}

.stripe {
  position: absolute;
  width: 5%;
  height: 5px;
  background-color: rgb(255, 255, 255);
  left: 50%;
}

.stripe1 {
  left: 35%;
}

.stripe2 {
  left: 65%;
}