/* Header section */
.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #ffffff;
    color: rgb(113, 130, 156);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #ccc;
    width: calc(100% - 12px);
    top: 0;
    z-index: 99;
  }

  .header.dark {
    background-color: rgb(52,53,65);
    color: rgba(197,197,210);
  }
  
  /* Hamburger icon section */
  .hamburger-logo {
    position: relative;
    display: flex;
    align-items: center;
  }

  .hamburger {
    position: relative;
    padding: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  .hamburger:hover {
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .hamburger.dark:hover {
    padding: 8px;
    border-radius: 8px;
    background-color: rgba(121, 118, 118, 0.2);
  }

  /* Logo section */
  .logo {
    width: 150px;
    height: auto;
    margin-left: 10px;
  }

  /* User info section */
  .user-info {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }
  
  .user-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #cccccc;
    color: #cccccc;
    margin-right: 10px;
  }

  /* User dropdown section */
  .user-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    background-color: rgb(239, 242, 244);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    transform-origin: top right;
    transform: scaleX(0) scaleY(0);
    transition: transform 0.3s ease-in-out;
}

.user-dropdown-content {
    display: flex;
    flex-direction: column;
    min-width: 130px;
    gap: 5px;
    padding: 12px;
}

.user-dropdown.open {
    transform: scaleX(1) scaleY(1);
}

.user-dropdown-icon {
    margin-left: 4px;
    width: 16px;
    height: 20px;
  }

  .user-dropdown-text {
    margin-left: 8px;
    margin-right: 4px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

.user-dropdown a {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    padding: 8px;
}

.user-dropdown a:hover {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.1);
}
  
  