/* Filter Section */
#filter-section {
    position: sticky;
    display: flex;
    justify-content: center;
    color: rgb(113, 130, 156);
    height: 120px;
    width: 100%;
    margin: 20px 0;
    top: 62px;
    z-index: 1;
  }

  .home-page #filter-content {
    width: 70%;
    margin: 20px auto;
}
  
  #filter-section.sticky {
    height: 120px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out, height 0.3s ease-in-out;
  }
  
  #filter-section.scrolled {
    background-color: #ffffff;
    height: 90px;
    transition: background-color 0.2s ease-in-out, height 0.2s ease-in-out;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }


  #filter-section.dark {
    color: rgba(197,197,210);
  }

  #filter-section.scrolled.dark {
    background-color: rgb(52,53,65);
  }

  /* Filter Content */
  #filter-content {
    position: relative;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    width: 85%;
    margin: 0 auto;
    left: 32px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }


  #filter-content.sticky {
    border-bottom: 1px solid #ccc;
  }

  #filter-content.scrolled {
    border-bottom: 0px solid #ccc;
    /* margin-bottom: 10px; */
  }

  /* Filter Header */
  #filter-header {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
  }

  #filter-header.sticky {
    font-size: 18px;
    transition: font-size 0.2s ease-in-out;
  }

  #filter-header.scrolled {
    font-size: 14px;
    transition: font-size 0.2s ease-in-out;
  }

  .filter-label{
    font-weight: 600;
  }

  /* Filter Section Buttons*/
  #filter-section button {
    margin-left: 5px;
    font-size: 12px;
    max-height: 20px;
    min-width: 40px;
    background-color: rgb(181, 182, 184);
    border-radius: 15px;
    margin-bottom: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
  }

  #filter-section button.active {
    background-color: #4CAF50;
    color: #fff;
  }

  #filter-section button:hover {
    background-color: rgb(153, 154, 155);
  }

  #filter-section button.active:hover {
    background-color: #429945;
  }

  /* Add spacing between rows */
  .filter-row {
    display: flex;
    flex-direction: row;
    height: 50%;
  }

  /* Position the Manager / Player toggle and label */
  .toggle-col {
    margin-right: 0;
    margin-left: auto;
  }

  .toggle-label {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    font-weight: 600;
  }

  /* Position and format the nav links */
  .nav-links {
    margin-right: 0;
    margin-left: auto;
  }

  .nav-link {
    margin-left: 20px;
    color: rgb(113, 130, 156);
    font-weight: 600;
    text-decoration: none;
    padding: 6px 0px;
  }

  .nav-link.dark {
    color: rgba(197,197,210);
  }

.nav-link.active{
    color:#4CAF50;
    border-bottom: 3px solid #4CAF50;
}

.nav-link:hover {
  color: #429945;
}

/* Dropdown section */
.custom-dropdown {
  position: flex;
  background-color: rgb(239, 242, 244);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  transform-origin: top;
  transform: scaleX(0) scaleY(0);
  transition: transform 0.3s ease-in-out;
}

.custom-dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px;
}

.custom-dropdown.open {
  transform: scaleX(1) scaleY(1);
}

.dropdown-selected {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 600;
}

.dropdown-text {
  color: #4CAF50;
  font-weight: 600;
  margin-left: 16px;
}

.dropdown-option {
  color: rgb(113, 130, 156);
  padding: 0px 12px;
  display: inline-block;
  text-decoration: none;
}

.dropdown-option:hover {
  border-radius: 4px;
  background-color: rgba(76, 175, 80, 0.2);
  cursor: pointer;
} 

@media (max-width: 800px) {
  .nav-link {
    display: none; /* Hide the navigation container */
  }
}


