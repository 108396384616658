.button-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
  }
    
  .nav-button {
    color: #4CAF50;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0 1rem;
    outline: none;
  }

  .nav-button.disabled {
    color: rgba(197,197,210);
  }

  .nav-button:hover {
    color: #429945;
  }

  .nav-button.disabled:hover {
    color: rgba(197,197,210);
  }

  .nav-button-hidden {
    color: transparent;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    padding: 0 1rem;
    outline: none;
  }
  
  .chart-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .filter-button {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    max-height: 20px;
    min-width: 40px;
    background-color: transparent;
    border-radius: 15px;
    margin-bottom: 5px;
    color: rgba(197,197,210);
    border: none;
    cursor: pointer;
  }

  .filter-button.active {
    color: #4CAF50;
  }

  .filter-button:hover {
    color: #429945;
  }

  .filter-button.QB.active {
    color: #fa8174;
  }

  .filter-button.QB:hover {
    color: #fa8174;
  }

  .filter-button.RB.active {
    color: #fac274;
  }

  .filter-button.RB:hover {
    color: #fac274;
  }

  .filter-button.WR.active {
    color: #fae474;
  }

  .filter-button.WR:hover {
    color: #fae474;
  }

  .filter-button.TE.active {
    color: #74fa9c;
  }

  .filter-button.TE:hover {
    color: #74fa9c;
  }

  .filter-button.K.active {
    color: #81b1d2;
  }

  .filter-button.K:hover {
    color: #81b1d2;
  }

  .filter-button.D.active {
    color: #e474fa;
  }

  .filter-button.D:hover {
    color: #e474fa;
  }
  